import { createApp, markRaw } from 'vue'
// Pinia
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// Loading Animation
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
// Validate Forms
import { Field, Form, ErrorMessage, defineRule, configure } from 'vee-validate'
import AllRules from '@vee-validate/rules'
// Font awesome
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
//  Notification
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import 'animate.css/animate.min.css'
// Axios
import axios from '@/utilities/axios'
import VueAxios from 'vue-axios'
// Utilities
import mitt from 'mitt'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import './assets/all.scss'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import router from './router'

// package for icon
import VueFeather from 'vue-feather'

Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule])
})
configure({
  bails: false
})
const emitter = mitt()
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
pinia.use(({ store }) => {
  store.$router = markRaw(router)
})
const options = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true
}
const app = createApp(App)
app.config.globalProperties.$emitter = emitter
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('LoadingOverlay', Loading)
app.component('VForm', Form)
app.component('VField', Field)
app.component('ErrorMessage', ErrorMessage)
app.component('vSelect', vSelect)
app.component('VDatepicker', Datepicker)
app.component('vue-feather', VueFeather)
app.use(ElementPlus)
app.use(Toast, options)
app.use(VueAxios, axios)
app.use(VueSweetalert2)
app.use(pinia)
app.use(router)
// app.use(VueMeta)
app.use(TawkMessengerVue, {
  propertyId: '6492ade594cf5d49dc5ef6f1',
  widgetId: '1h3efeis8'
})
app.mount('#app')
