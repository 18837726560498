import { createRouter, createWebHashHistory } from 'vue-router'
import { ElMessage } from 'element-plus'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomePage.vue'),
    meta: {
      title: 'Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/Admin/AdminDashboard.vue'),
    children: [
      {
        path: 'customer-requests',
        name: 'customer-requests',
        component: () => import('@/views/Admin/RequireApprovalList.vue'),
        meta: {
          title: 'Customer Request | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'web-orders',
        name: 'admin-web-orders',
        component: () => import('@/views/Admin/WebOrders.vue'),
        meta: {
          title: 'Web Orders | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'banner',
        name: 'admin-banner',
        component: () => import('@/views/Admin/BannerManagement.vue'),
        meta: {
          title: 'Manage Banners | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'products',
        name: 'admin-products',
        component: () => import('@/views/Admin/ProductsList.vue'),
        meta: {
          title: 'Manage Products | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edit/:product',
        name: 'admin-edit-product',
        component: () => import('@/views/Admin/ProductEditor.vue'),
        meta: {
          title: 'Edit Products | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'subcategories',
        name: 'admin-subcategories',
        component: () => import('@/views/Admin/SubcategoryList.vue'),
        meta: {
          title: 'Manage Subcategories | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'users',
        name: 'admin-users',
        component: () => import('@/views/Admin/UserList.vue'),
        meta: {
          title: 'Manage Users | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'web-customers',
        name: 'admin-web-customers',
        component: () => import('@/views/Admin/WebCustomer.vue'),
        meta: {
          title: 'Web Customers | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sub-colours',
        name: 'admin-sub-colours',
        component: () => import('@/views/Admin/SubColour.vue'),
        meta: {
          title: 'Sub Colours | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      }
    ]
  },
  {
    path: '/admin/login',
    name: 'admin/login',
    component: () => import('@/views/Admin/AdminLogin.vue'),
    meta: {
      title: 'Admin Login | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About/AboutUs.vue'),
    meta: {
      title: 'About Us | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/About/ContactUs.vue'),
    meta: {
      title: 'Contact Us | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/views/ProwoodFinance.vue'),
    meta: {
      title: 'Finance | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/Order/OrderResult.vue'),
    meta: {
      requiresAuth: true,
      title: 'Order | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/place-order',
    name: 'place-order',
    component: () => import('@/views/Order/EasiglideOrderResult.vue'),
    meta: {
      requiresAuth: true,
      title: 'Order | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/Member/OrderList.vue'),
    meta: {
      requiresAuth: true,
      title: 'Orders | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/addresses',
    name: 'addresses',
    component: () => import('@/views/Member/AddressList.vue'),
    meta: {
      requiresAuth: true,
      title: 'Addresses | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/paymentanddispatch',
    name: 'paymentanddispatch',
    component: () => import('@/views/Policy/PaymentDispatch.vue'),
    meta: {
      title: 'payment and Dispatch | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: () => import('@/views/Policy/PrivacyPolicy.vue'),
    meta: {
      title: 'Privacy Policy | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/Products/AllProducts.vue'),
    meta: {
      title: 'Products | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/products/:product',
    name: 'product-detail',
    component: () => import('@/views/Products/ProductDetails.vue'),
    meta: {
      title: 'Product Detail | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/member',
    name: 'member',
    component: () => import('@/views/Member/MemberProfile.vue'),
    meta: {
      requiresAuth: true,
      title: 'Profile | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/Member/SignUp.vue'),
    meta: {
      title: 'Register | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/Member/VerifyEmail.vue'),
    meta: {
      title: 'Verify Email | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/return',
    name: 'return',
    component: () => import('@/views/Policy/ReturnToUs.vue'),
    meta: {
      title: 'Return | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/termsconditions',
    name: 'termsconditions',
    component: () => import('@/views/Policy/TermsConditions.vue'),
    meta: {
      title: 'Term and Conditions | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    component: () => import('@/views/Member/WishList.vue'),
    meta: {
      requiresAuth: true,
      title: 'Wishlists | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/Order/ShoppingCart.vue'),
    meta: {
      requiresAuth: true,
      title: 'Carts | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/shippinginfo',
    name: 'shippinginfo',
    component: () => import('@/views/Order/ShippingInfo.vue'),
    meta: {
      requiresAuth: true,
      title: 'Shipping Info | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/easiglide-door-order',
    name: 'easiglide-door-order',
    component: () => import('@/views/Order/EasiglideShippingInfo.vue'),
    meta: {
      requiresAuth: true,
      title: 'Shipping Info | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/door-system',
    name: 'door-system',
    component: () => import('@/views/Products/DoorSystem.vue'),
    meta: {
      title: 'Shipping Info | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/Order/CheckOut.vue'),
    meta: {
      title: 'Payment | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/door-payment',
    name: 'door-payment',
    component: () => import('@/views/Order/EasiglideCheckOut.vue'),
    meta: {
      title: 'Payment | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/Products/SearchPage.vue'),
    meta: {
      title: 'Search Results | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/3dservice',
    name: '3dservice',
    component: () => import('@/views/Products/3DService.vue'),
    meta: {
      title: '3D Service | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/easiglide',
    name: 'easiglide',
    component: () => import('@/views/Products/EasiglideDoors.vue'),
    meta: {
      title: 'Easiglide Door Entry System | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/easiglide-intro',
    name: 'easiglide-intro',
    component: () => import('@/views/Products/EasiglideDoorsIntro.vue'),
    meta: {
      title: 'Easiglide Door Entry System | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/new-machinery',
    name: 'new-machinery',
    children: [
      {
        path: 'edgebanding',
        name: 'edgebanding',
        component: () => import('@/views/Machines/Edgebanding/EdgebandingDetails.vue')
      },
      {
        path: 'edgebanding/straight-line-edgebanders',
        name: 'straight-line-edgebanders',
        component: () => import('@/views/Machines/Edgebanding/StraightLineEdgebander/StraightLineEdgebanderDetail.vue'),
        meta: {
          title: 'Straight Line Edgebanders | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/straight-line-edgebanders/lohmeyer',
        name: 'lohmeyer',
        component: () => import('@/views/Machines/Edgebanding/StraightLineEdgebander/LohmeyerDetails.vue'),
        meta: {
          title: 'Lohmeyer | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/straight-line-edgebanders/fravol-rapid-series',
        name: 'fravol-rapid-series',
        component: () => import('@/views/Machines/Edgebanding/StraightLineEdgebander/FravolRapidSeries.vue'),
        meta: {
          title: 'Favrol Rapid Series | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/straight-line-edgebanders/fravol-smart-series',
        name: 'fravol-smart-series',
        component: () => import('@/views/Machines/Edgebanding/StraightLineEdgebander/FravolSmartSeries.vue'),
        meta: {
          title: 'Favrol Smart Series | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/straight-line-edgebanders/fravol-master-series',
        name: 'fravol-master-series',
        component: () => import('@/views/Machines/Edgebanding/StraightLineEdgebander/FravolMasterSeries.vue'),
        meta: {
          title: 'Favrol Master Series | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/straight-line-edgebanders/fravol-fast-series',
        name: 'fravol-fast-series',
        component: () => import('@/views/Machines/Edgebanding/StraightLineEdgebander/FravolFastSeries.vue'),
        meta: {
          title: 'Favrol Fast Series | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/straight-line-edgebanders/edm-kdp-606-airtronic',
        name: 'edm-kdp-606-airtronic',
        component: () => import('@/views/Machines/Edgebanding/StraightLineEdgebander/EBMKDP606Airtronic.vue'),
        meta: {
          title: 'EDM KDP 606 Airitronic | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/straight-line-edgebanders/edm-kdp-72-74',
        name: 'edm-kdp-72-74',
        component: () => import('@/views/Machines/Edgebanding/StraightLineEdgebander/EBMKDP72And74.vue'),
        meta: {
          title: 'EDM KDP 72 & 74 | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/straight-line-edgebanders/edm-kdp-135-136',
        name: 'edm-kdp-135-136',
        component: () => import('@/views/Machines/Edgebanding/StraightLineEdgebander/EBMKDP135And136.vue'),
        meta: {
          title: 'EDM KDP 135 & 136 | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/straight-line-edgebanders/edm-kdp-208',
        name: 'edm-kdp-208',
        component: () => import('@/views/Machines/Edgebanding/StraightLineEdgebander/EBMKDP208.vue'),
        meta: {
          title: 'EDM KDP 208 | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/straight-line-edgebanders/virtuex-eb25',
        name: 'virtuex-eb25',
        component: () => import('@/views/Machines/Edgebanding/StraightLineEdgebander/VirtuexEB25.vue'),
        meta: {
          title: 'Virutex EB25 | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/hot-air-edgebanders',
        name: 'hot-air-edgebanders',
        component: () => import('@/views/Machines/Edgebanding/HotAirEdgebander/HotAirEdgebanderDetail.vue'),
        meta: {
          title: 'Hot Air Edgebanders | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/shaped-edgebanders',
        name: 'shaped-edgebanders',
        component: () => import('@/views/Machines/Edgebanding/ShapedEdgebander/ShapedEdgebanderDetail.vue'),
        meta: {
          title: 'Shaped Edgebanders | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/trimming-rounding',
        name: 'trimming-rounding',
        component: () => import('@/views/Machines/Edgebanding/TrimmingAndRounding/TrimmingAndRoundingDetail.vue'),
        meta: {
          title: 'Trimming & Rounding | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edgebanding/trimming-rounding/virutex-pae85t',
        name: 'virutex-pae85t',
        component: () => import('@/views/Machines/Edgebanding/TrimmingAndRounding/VirtuexPAE85TDetail.vue'),
        meta: {
          title: 'Virtuex PAE85T | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing',
        name: 'sawing-sizing',
        component: () => import('@/views/Machines/SawAndSizing/SawingAndSizingDetails.vue'),
        meta: {
          title: 'Sawing & Sizing | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/manual-sizing',
        name: 'manual-sizing',
        component: () => import('@/views/Machines/SawAndSizing/ManualSizing/ManualSizingDetail.vue'),
        meta: {
          title: 'Manual Sizing | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/manual-sizing/panel-saws',
        name: 'panel-saws',
        component: () => import('@/views/Machines/SawAndSizing/ManualSizing/PanelSawDetail.vue'),
        meta: {
          title: 'Manual Sizing | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/manual-sizing/paoloni-p45-q45-panel-saws',
        name: 'paoloni-p45-q45-panel-saws',
        component: () => import('@/views/Machines/SawAndSizing/ManualSizing/PaoloniP45AndQ45Detail.vue'),
        meta: {
          title: 'Paoloni Q45 & P45 Panel Saws | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/manual-sizing/paoloni-3200ax-sx-nx',
        name: 'paoloni-3200ax-sx-nx',
        component: () => import('@/views/Machines/SawAndSizing/ManualSizing/Paoloni3200AXSXNXDetail.vue'),
        meta: {
          title: 'Paoloni 3200 AX, SX & NX | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/manual-sizing/paoloni-p450-ax',
        name: 'paoloni-p450-ax',
        component: () => import('@/views/Machines/SawAndSizing/ManualSizing/PaoloniP450AXDetail.vue'),
        meta: {
          title: 'Paoloni P450 AX | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/manual-sizing/ney-n45',
        name: 'ney-n45',
        component: () => import('@/views/Machines/SawAndSizing/ManualSizing/NEYN45Detail.vue'),
        meta: {
          title: 'NEY N45 | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/manual-sizing/band-saw',
        name: 'band-saw',
        component: () => import('@/views/Machines/SawAndSizing/ManualSizing/BandSawDetail.vue'),
        meta: {
          title: 'Band Saw | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/manual-sizing/upcut-saw',
        name: 'upcut-saw',
        component: () => import('@/views/Machines/SawAndSizing/ManualSizing/UpcutSawDetail.vue'),
        meta: {
          title: 'Upcut Saw | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/automatic-sizing',
        name: 'automatic-sizing',
        component: () => import('@/views/Machines/SawAndSizing/AutomaticSizing/AutomaticSizingDetail.vue'),
        meta: {
          title: 'Automatic Sizing | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/automatic-sizing/paoloni',
        name: 'paoloni',
        component: () => import('@/views/Machines/SawAndSizing/AutomaticSizing/PaoloniDetail.vue'),
        meta: {
          title: 'Paoloni | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/automatic-sizing/lohmeyer',
        name: 'sawing-lohmeyer',
        component: () => import('@/views/Machines/SawAndSizing/AutomaticSizing/LohmeyerDetail.vue'),
        meta: {
          title: 'Lohmeyer | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/hybrid',
        name: 'hybrid',
        component: () => import('@/views/Machines/SawAndSizing/Hybrid/HybridDetail.vue'),
        meta: {
          title: 'Hybrid | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/hybrid/concept-350-plus',
        name: 'concept-350-plus',
        component: () => import('@/views/Machines/SawAndSizing/Hybrid/Concept350PlusDetail.vue'),
        meta: {
          title: 'Concept 350 Plus | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sawing-sizing/hybrid/kr-spin-rotomatic',
        name: 'kr-spin-rotomatic',
        component: () => import('@/views/Machines/SawAndSizing/Hybrid/KRSpinRotomaticDetail.vue'),
        meta: {
          title: 'KR Spin Rotomatic | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'cnc-machines',
        name: 'cnc-machines',
        component: () => import('@/views/Machines/CNCMachines/CNCMachineDetail.vue'),
        meta: {
          title: 'CNC Machines | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'cnc-machines/flat-table-format',
        name: 'flat-table-format',
        component: () => import('@/views/Machines/CNCMachines/FlatTableFormat/FlatTableFormatDetail.vue'),
        meta: {
          title: 'Flat Table Format | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'cnc-machines/flat-table-format/lohmeyer-range',
        name: 'lohmeyer-range',
        component: () => import('@/views/Machines/CNCMachines/FlatTableFormat/LohmeyerRangeDetail.vue'),
        meta: {
          title: 'Lohmeyer Range | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'cnc-machines/pod-and-rail-format',
        name: 'pod-and-rail-format',
        component: () => import('@/views/Machines/CNCMachines/PodAndRailFormat/PodAndRailFormatDetail.vue'),
        meta: {
          title: 'Pod and Rail Format | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'cnc-machines/pod-and-rail-format/lohmeyer-baz-875n',
        name: 'lohmeyer-baz-875n',
        component: () => import('@/views/Machines/CNCMachines/PodAndRailFormat/LohmeyerBaz875NDetail.vue'),
        meta: {
          title: 'Lohmeyer Baz 875n | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'cnc-machines/cnc-drilling-insertion',
        name: 'cnc-drilling-insertion',
        component: () => import('@/views/Machines/CNCMachines/CNCDrillingAndInsertion/CNCDrillingAndInsertionDetail.vue'),
        meta: {
          title: 'CNC Drilling & Insertion | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'cnc-machines/cnc-drilling-insertion/alberti-t-flight',
        name: 'alberti-t-flight',
        component: () => import('@/views/Machines/CNCMachines/CNCDrillingAndInsertion/AlbertiTFlightDetail.vue'),
        meta: {
          title: 'Alberti T-Flight | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'cnc-machines/cnc-drilling-insertion/vitap-cnc-drilling',
        name: 'vitap-cnc-drilling',
        component: () => import('@/views/Machines/CNCMachines/CNCDrillingAndInsertion/VitapCNCDrillingDetail.vue'),
        meta: {
          title: 'Vitap CNC Drilling | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'cnc-machines/cnc-drilling-insertion/blitz-2',
        name: 'blitz-2',
        component: () => import('@/views/Machines/CNCMachines/CNCDrillingAndInsertion/Blitz2Detail.vue'),
        meta: {
          title: 'Blitz-2 | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'cnc-machines/cnc-drilling-insertion/polycab-software',
        name: 'polycab-software',
        component: () => import('@/views/Machines/CNCMachines/PolyCABSoftware/PolyCABSoftwareDetail.vue'),
        meta: {
          title: 'PolyCAB Software | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'drilling-assembly',
        name: 'drilling-assembly',
        component: () => import('@/views/Machines/DrillingAndAssemblyRange/DrillingAndAssemblyRangeDetail.vue'),
        meta: {
          title: 'Drilling & Assembly | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'drilling-assembly/detel-cbm-1',
        name: 'detel-cbm-1',
        component: () => import('@/views/Machines/DrillingAndAssemblyRange/DetelCBM1Detail.vue'),
        meta: {
          title: 'Detel CBM-1 | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'drilling-assembly/uniholz',
        name: 'uniholz',
        component: () => import('@/views/Machines/DrillingAndAssemblyRange/UniholzDetail.vue'),
        meta: {
          title: 'Uniholz | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'drilling-assembly/top-vertical-drillingrouting',
        name: 'top-vertical-drillingrouting',
        component: () => import('@/views/Machines/DrillingAndAssemblyRange/TopVerticalDrillingRoutingDetail.vue'),
        meta: {
          title: 'Top - Vertical Drilling/Routing | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'drilling-assembly/unver',
        name: 'unver',
        component: () => import('@/views/Machines/DrillingAndAssemblyRange/UnverDetail.vue'),
        meta: {
          title: 'Unver | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'solid-wood',
        name: 'solid-wood',
        component: () => import('@/views/Machines/SolidWood/SolidWoodDetail.vue'),
        meta: {
          title: 'Solid Wood | Woodworking Machinery, Materials and Components -NEY Ltd'
        }
      },
      {
        path: 'solid-wood/paoloni-machines',
        name: 'paoloni-machines',
        component: () => import('@/views/Machines/SolidWood/Paoloni/PaoloniDetail.vue'),
        meta: {
          title: 'Paoloni Machines | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'solid-wood/paoloni-machines/combined-machines',
        name: 'combined-machines',
        component: () => import('@/views/Machines/SolidWood/Paoloni/CombinedMachinesDetail.vue'),
        meta: {
          title: 'Combined Machines | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'solid-wood/paoloni-machines/thicknessing-machines',
        name: 'thicknessing-machines',
        component: () => import('@/views/Machines/SolidWood/Paoloni/ThicknessingMachineDetail.vue'),
        meta: {
          title: 'Thicknessing Machine | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'solid-wood/paoloni-machines/planning-machines',
        name: 'planning-machines',
        component: () => import('@/views/Machines/SolidWood/Paoloni/PlanningMachinesDetail.vue'),
        meta: {
          title: 'Planning Machines | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'solid-wood/paoloni-machines/spindle-moulders',
        name: 'spindle-moulders',
        component: () => import('@/views/Machines/SolidWood/Paoloni/SpindleMouldersDetail.vue'),
        meta: {
          title: 'Spindle Moulders | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'solid-wood/unique-gt250',
        name: 'unique-gt250',
        component: () => import('@/views/Machines/SolidWood/UniqueGT250Detail.vue'),
        meta: {
          title: 'Unique GT250 | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sanding-finishing',
        name: 'sanding-finishing',
        component: () => import('@/views/Machines/SandingAndFinishing/SandingAndFinishingDetail.vue'),
        meta: {
          title: 'Sanding & Finishing | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sanding-finishing/sarmax-machine',
        name: 'sarmax-machine',
        component: () => import('@/views/Machines/SandingAndFinishing/SarmaxMachineDetail.vue'),
        meta: {
          title: 'Sarmax Machine | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sanding-finishing/wide-belt-sanders',
        name: 'wide-belt-sanders',
        component: () => import('@/views/Machines/SandingAndFinishing/WideBeltSandersDetail.vue'),
        meta: {
          title: 'Wide Belt Sanders | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'packaging-handling',
        name: 'packaging-handling',
        component: () => import('@/views/Machines/PackagingAndHandling/PackagingAndHandlingDetail.vue'),
        meta: {
          title: 'Packaging & Handling | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'packaging-handling/spiral-wrapping/gg-box-500-spiral-wrapper',
        name: 'gg-box-500-spiral-wrapper',
        component: () => import('@/views/Machines/PackagingAndHandling/GGBox500SpiralWrapperDetail.vue'),
        meta: {
          title: 'GG Box 500 Spiral Wrapper | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'packaging-handling/spiral-wrapping/gg-at1200a-spiral-wrapper',
        name: 'gg-at1200a-spiral-wrapper',
        component: () => import('@/views/Machines/PackagingAndHandling/GGAT1200ASpiralWrapperDetail.vue'),
        meta: {
          title: 'GG AT1200A Spiral Wrapper | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'packaging-handling/spiral-wrapping/gg-tc1200-total-closing',
        name: 'gg-tc1200-total-closing',
        component: () => import('@/views/Machines/PackagingAndHandling/GGTC1200TotalClosingDetail.vue'),
        meta: {
          title: 'GG TC1200 Total Closing | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'packaging-handling/spiral-wrapping/gg-giotto-1300-total-closing',
        name: 'gg-giotto-1300-total-closing',
        component: () => import('@/views/Machines/PackagingAndHandling/GGGiotto1300TotalClosingDetail.vue'),
        meta: {
          title: 'GG Giotto 1300 Total Closing | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'foilcoating-pressing',
        name: 'foilcoating-pressing',
        component: () => import('@/views/Machines/FoilcoatingAndPressing/FoilcoatingPressingDetail.vue'),
        meta: {
          title: 'Foilcoating & Pressing | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'foilcoating-pressing/omc-airtec',
        name: 'omc-airtec',
        component: () => import('@/views/Machines/FoilcoatingAndPressing/OMCAirtechDetail.vue'),
        meta: {
          title: 'OMC Airtec | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'foilcoating-pressing/rapha',
        name: 'rapha',
        component: () => import('@/views/Machines/FoilcoatingAndPressing/Rapha/RaphaDetail.vue'),
        meta: {
          title: 'Rapha | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'foilcoating-pressing/rapha/rapha-membranes-protection-covers',
        name: 'rapha-membranes-protection-covers',
        component: () => import('@/views/Machines/FoilcoatingAndPressing/Rapha/RaphaMembranesDetail.vue'),
        meta: {
          title: 'Rapha Membaranes & Protection Covers | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'foilcoating-pressing/rapha/rapha-cleaning-finishing',
        name: 'rapha-cleaning-finishing',
        component: () => import('@/views/Machines/FoilcoatingAndPressing/Rapha/CleaningFinishingDetail.vue'),
        meta: {
          title: 'Rapha Cleaning & Finishing | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'foilcoating-pressing/rapha/rapha-paternoster',
        name: 'rapha-paternoster',
        component: () => import('@/views/Machines/FoilcoatingAndPressing/Rapha/RaphaPaternosterDetail.vue'),
        meta: {
          title: 'Rapha Paternoster | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'foilcoating-pressing/rapha/rapha-pin-systems',
        name: 'rapha-pin-system',
        component: () => import('@/views/Machines/FoilcoatingAndPressing/Rapha/RaphaPinSystemDetail.vue'),
        meta: {
          title: 'Rapha Pin System | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'foilcoating-pressing/rapha/turn-over-system',
        name: 'turn-over-system',
        component: () => import('@/views/Machines/FoilcoatingAndPressing/Rapha/TurnOverSystemDetail.vue'),
        meta: {
          title: 'Turn Over System | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'foilcoating-pressing/columbus',
        name: 'columbus',
        component: () => import('@/views/Machines/FoilcoatingAndPressing/ColumbusDetail.vue'),
        meta: {
          title: 'Columbus | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'foilcoating-pressing/steton',
        name: 'steton',
        component: () => import('@/views/Machines/FoilcoatingAndPressing/StetonDetail.vue'),
        meta: {
          title: 'Steton | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'foilcoating-pressing/casati-machines',
        name: 'casati-machines',
        component: () => import('@/views/Machines/FoilcoatingAndPressing/CasatiMachineDetail.vue'),
        meta: {
          title: 'Casati Machines | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'foilcoating-pressing/osama',
        name: 'osama',
        component: () => import('@/views/Machines/FoilcoatingAndPressing/OsamaDetail.vue'),
        meta: {
          title: 'Osama | Foilcoating & Pressing | New Machinery | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/components/404Page.vue')
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (nearestWithMeta) {
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
      .forEach(tag => document.head.appendChild(tag))
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = document.cookie.replace(/(?:(?:^|.*;\s*)access_token\s*=\s*([^;]*).*$)|^.*$/, '$1')
  if (requiresAuth && !isAuthenticated) {
    ElMessage.error({ message: 'Please login first.', showClose: true })
    next('/')
  } else {
    next()
  }
})

export default router
