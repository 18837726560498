import axios from 'axios'
import Swal from 'sweetalert2'
import router from '../router'

const eventsRequest = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    'Content-Type': 'application/json'
  }
})

eventsRequest.interceptors.request.use(
  function (config) {
    // const apikey = document.cookie.replace(/(?:(?:^|.*;\s*)API_key\s*=\s*([^;]*).*$)|^.*$/, '$1')
    // config.headers['API-KEY'] = apikey
    if (config.url.match(/^\/admin(\/|$)/)) {
      // set the admin_token header for admin URLs
      const adminToken = document.cookie.replace(/(?:(?:^|.*;\s*)admin_token\s*=\s*([^;]*).*$)|^.*$/, '$1')
      if (adminToken) {
        config.headers.Authorization = `Bearer ${adminToken}`
      } else {
        router.push('/admin/login') // replace with the name of your admin login route
        return Promise.reject(new Error('Admin token not set'))
      }
    } else {
      // set the access_token header for all other URLs
      const token = document.cookie.replace(/(?:(?:^|.*;\s*)access_token\s*=\s*([^;]*).*$)|^.*$/, '$1')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

eventsRequest.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401 && !window.refreshing) {
      // window.location = '/'
    } else if (error.response.status === 422 || error.response.status === 400) {
      // window.location = '/'
    } else if (error.response.status === 403) {
      // document.cookie = 'access_token = ; expires = ;'
      // router.push('/')
    } else if (error.response.status === 404) {
      console.log(error)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!'
      })
    }
    return Promise.reject(error)
  }
)
export default eventsRequest
